import React, { useEffect, useState } from "react";
import useEncryption from "../../useEncryption/useEncryption";
import axiosInstanceAuth from "../../apiInstances/axiosInstanceAuth";
// import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import CountUp from "react-countup";

const DashBoard = () => {
  const { decryptData } = useEncryption();
  const [dashboardData, setDashboardData] = useState([]);
  console.log(dashboardData, "-----------dashboardData");

  const getAllGraphData = async () => {
    await axiosInstanceAuth
      .get("/admin/graphs")
      .then((res) => {
        const myData = decryptData(res?.data?.data);
        console.log(myData, "........................myData");

        setDashboardData(myData?.data);
      })
      .catch((err) => {
        console.log("err --->", err);
      });
  };

  useEffect(() => {
    getAllGraphData();
  }, []);

  return (
    <>
      <div className="ml-3 lg:ml-64 xl:ml-96 md:mx-5 lg:mx-10 xl:pt-20 ">
        <div className="container mx-auto px-4 xl:px-12 align-center pt-5">
          <div className="mt-20">
            <div className="grid  place-items-start text-lg w-full lg:text-2xl font-extrabold pb-10 text-white">
              <span className="text-start  bg-[#428b92] rounded-xl w-full p-5 opacity-2">
                <p className="text-3xl xl:text-4xl lg:leading-[3rem]  leading-10 font-medium">
                  Welcome back!!
                </p>
                <p className="xl:text-2xl italic text-justify  text-lg font-normal mt-5">
                  Explore Your Own Powerful Administration Panel and Track
                  Everything With Quick Reports.
                </p>
              </span>
            </div>
            <div className="grid grid-cols-2 xl:grid-cols-4 gap-10 mt-10 pb-10">
              {dashboardData?.length > 0 &&
                dashboardData.map((d, index) => (
                  <Link to={d.path} key={index}>
                    <div className="">
                      <div className="backs1 h-[180px] bg-slate-700 bg-transparent opacity-2 text-white rounded-xl p-4 shadow-2xl transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-slate-700 duration-300">
                        <h3 className="text-sm ">{d?.title} </h3>
                        <div className="border my-4 border-[#f47a5b]" />
                        <div className="text-center mt-10">
                          <h3 className="text-sm">{d?.range} </h3>
                          <h3>{d?.value} </h3>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashBoard;
