import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate, useParams } from "react-router-dom";
import useEncryption from "../../useEncryption/useEncryption";
// import axiosInstance from '../../apiInstances/axiosInstance';
import axiosInstanceAuth from '../../apiInstances/axiosInstanceAuth';
import { toast } from 'react-toastify';


const ResetBtn = () => {
    let { id } = useParams();
    // console.log(id);
    const { decryptData } = useEncryption();
    const navigate = useNavigate()

    const [reset, setReset] = useState({
        password: " ",
        cpassword: " "
    });

    const onChangeInput = (e) => {
        const value = e.target.value.replace(/^\s+|\s+$/gm, "");

        const name = e.target.name;
        setReset({
            ...reset, [name]: value,
        }
        )
    };
    const data = {
        password: reset?.password,
        cpassword: reset?.cpassword

    };

    const RePassword = async () => {
        await axiosInstanceAuth
            .post(`/admin/resetpassword/${id}`, data)
            .then((res) => {
                const myData = decryptData(res?.data?.data);
                if (myData?.status) {
                    navigate("/UaerTable");
                    toast.success(myData?.message);
                } else {
                    toast.error(myData?.message);
                }

            })
            .catch((err) => {
                console.log(err)
            });
    }
    return (
        <>
            <section class="bg-gray-50 bg-slate-800">
                <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                    <div class="w-full p-6 bg-white rounded-lg shadow  md:mt-0 sm:max-w-md  sm:p-8">
                        <h2 class="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
                            Change Password
                        </h2>
                        <form className="mt-4 space-y-4 lg:mt-5 md:space-y-5" action="#">
                            <div>
                                <label for="password" class="block mb-2 text-sm font-medium text-gray-900 ">New Password</label>
                                <input type="password" name="password" id="password" placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  " required=""
                                    onChange={onChangeInput} />
                            </div>
                            <div>
                                <label for="cpassword" class="block mb-2 text-sm font-medium text-gray-900 ">Confirm password</label>

                                <input type="password" name="cpassword" id="cpassword" placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 " required=""
                                    onChange={onChangeInput} />
                            </div>
                            <button type="submit" class="w-full text-white bg-orange hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center" onClick={(e) => RePassword(e.preventDefault())}>Reset passwod</button>
                        </form>
                    </div>
                </div>
            </section>
            {/* <h1 className='bg-blue-600 '>hiiiii</h1> */}
        </>
    )
}

export default ResetBtn