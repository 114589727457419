import React, { useEffect, useState } from "react";
import useEncryption from "../../useEncryption/useEncryption";
import axiosInstanceAuth from "../../apiInstances/axiosInstanceAuth";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { RiDeleteBin6Line } from "@react-icons/all-files/ri/RiDeleteBin6Line";


const Gift = () => {
  const navigate = useNavigate();
  const { decryptData } = useEncryption();
  const [active, setActive] = useState(1);

  let { id } = useParams();
  // console.log("id================>>>", id);
  useEffect(() => {
    getUserData(id);
  }, []);

  const [DeletePopUp, setDeletePopUp] = useState(false);
  const [SelectedUser, setSelectedUser] = useState("");
  const [AllUsers, setAllUsers] = useState([]);
  // console.log("=/=/=/=/=//=/=/=/=/=/AllUsers", AllUsers);

  const ConfirmDelete = (SelectedUser) => {
    deleteBlog(SelectedUser);
  };

  const OpenPopUp = (_id) => {
    setSelectedUser(_id);
    setDeletePopUp(true);
  };

  const ClosePopUp = () => {
    setDeletePopUp(false);
    setSelectedUser("");
  };

  const getUserData = async () => {
    await axiosInstanceAuth
      .get(`/admin/foldergift/${id}`)
      .then((res) => {
        const myData = decryptData(res?.data?.data);
        // console.log("myData================>>>", myData);
        setAllUsers(myData.data);
        // console.log(myData, "ggggg,,,,,,,,,,");
        if (myData?.status) {
        } else {
          toast.error(myData?.message);
        }
      })
      .catch((err) => {
        console.log("err --->", err);
      });
  };

  const deleteBlog = async (SelectedUser) => {
    await axiosInstanceAuth
      .post(`/admin/deletegift/${id}`, { id: SelectedUser })
      .then((res) => {
        const myData = decryptData(res?.data?.data);
        // console.log("delete-------------> ", myData);

        if (myData?.status) {
          ClosePopUp();
          getUserData();
          toast.success("Gift Deleted Successfuly");
        } else {
          toast.error(myData?.message);
        }
      })
      .catch((err) => {
        console.log("err --->", err);
      });
  };

  const sortingData = (e) => {
    let type = e.target.textContent.toLowerCase();
    const sorted = [...AllUsers].sort((a, b) =>
      a[type] > b[type] ? 1 : b[type] > a[type] ? -1 : 0
    );
    setAllUsers(sorted);
    // console.log("sorting-->");
  };

  // const UserSidebarStyle =
  //   "py-4 px-8 my-8 hover:bg-[#4BADB8] hover:text-white rounded-full cursor-pointer capitalize  shadow-xl";
  // const ActiveUserSidebarStyle =
  //   "py-4 px-8 my-8 bg-[#4BADB8] rounded-full cursor-pointer capitalize text-white shadow-xl";

  // const handleClick = (current) => () => {
  //   setActive(current);
  // };
  // search bar++++++++++++++
  const [searchGift, setSearchGift] = useState("");
  console.log(searchGift);

  return (
    <div className="flex flex-col ml-3 lg:ml-64 xl:ml-96 md:mx-5 pt-10">
      <div className="container mx-auto py-10">
        <div className="">
          <div className="flex justify-center items-center mb-5">
            <div className="relative text-gray-600 focus-within:text-gray-400">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                <button
                  type="submit"
                  className="p-1 focus:outline-none focus:shadow-outline"
                >
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2" 
                    viewBox="0 0 24 24"
                    className="w-6 h-6 text-gray-400"
                  >
                    <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                  </svg>
                </button>
              </span>
              <input
                type="search"
                name="q"
                className="text-lg w-[600px] p-2 pl-10 text-gray-400 bg-slate-700 sm:rounded-lg  focus:outline-none "
                placeholder="Search..."
                autoComplete="off"
                onChange={(e) => setSearchGift(e.target.value)}
              />
            </div>
          </div>
        </div>
        {/* <<----- User Data ----->> */}
        <div className="relative overflow-x-auto shadow-2xl sm:rounded-lg ">
          <table className="min-w-full text-sm text-left text-gray-500 ">
            {active === 1 && (
              <>
                <thead className="text-xs font-medium text-gray-400 bg-gray-50 bg-gray-700 uppercase  ">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      {`ID`}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3  "
                      onClick={sortingData}
                    >
                      Gift Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 "
                      onClick={sortingData}
                    >
                      URL
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 "
                      onClick={sortingData}
                    >
                      Gift Price
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 "
                      onClick={sortingData}
                    >
                      Gift Purchase Status
                    </th>
                    <th
                      scope="col"
                      className=" text-center"
                      onClick={sortingData}
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {AllUsers?.length > 0 &&
                    AllUsers?.filter((e) => {
                      return searchGift.toLowerCase() === ""
                        ? e
                        : e.title.toLowerCase().includes(searchGift) ||
                            e.price.toLowerCase().includes(searchGift);
                    }).map((e, ind) => (
                      <tr
                        key={ind}
                        className="bg-gradient-to-r from-[#2f2f49] to-[#191a49] text-white"
                      >
                        <th
                          scope="row"
                          className="px-6 py-4 text-gray-200 whitespace-nowrap "
                        >
                          {ind + 1}
                        </th>
                        <td
                          scope="row"
                          className="px-6 py-4 text-gray-200 whitespace-nowrap capitalize"
                        >
                          {e?.title}
                        </td>
                        <td className="px-6 py-4 text-gray-200 ">
                          {e?.webViewLink.slice(0, 30)}
                        </td>

                        <td className="px-6 py-4  text-gray-200  ">
                          {e?.price}
                        </td>
                        <td
                          // scope="row"
                          className="px-6 py-4 text-gray-200 whitespace-nowrap   capitalize"
                          onClick={() => {}}
                        >
                          {e?.starredGift ? "yes" : "no"}
                        </td>

                        <td className="px-6 py-4 text-center flex">
                          <button
                            className="text-center cursor-pointer text-green-500 mr-5 rounded-md py-1 px-2"
                            onClick={() =>
                              navigate(`/gift/edit?id=${e?._id}&folderid=${id}`)
                            }
                          >
                            Edit
                          </button>

                          {/* try */}

                          <button
                            type="submit"
                            className="text-red-700 py-1"
                            onClick={() => OpenPopUp(e?._id)}
                          >
                            <RiDeleteBin6Line size={23} />
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </>
            )}
          </table>
        </div>
      </div>
      {/* <<----- Change Status Pop Up ----->> */}
      {DeletePopUp ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[999999]  outline-none focus:outline-none border ">
            <div className="relative min-w-[250px] max-w-[90%] mx-auto  my-10 shadow-black shadow-2xl">
              {/* ------ Content ------ */}
              <div className="border-0 rounded-lg shadow-2xl relative flex flex-col w-full bg-slate-500 outline-none focus:outline-none">
                {/* ------ Header ------ */}
                <div className="grid place-items-center place-content-end">
                  <button
                    className="bg-transparent border-0 text-black opacity-9 text-2xl font-normal outline-none focus:outline-none mx-3 my-2"
                    onClick={(e) => ClosePopUp()}
                  >
                    ×
                  </button>
                </div>
                {/* ------ Body ------ */}
                <div className="relative grid place-items-center px-6 md:px-10 py-3 flex-auto">
                  <h3 className="text-black font-semibold text-base md:text-lg  leading-relaxed text-center">
                    Are You Sure ?
                  </h3>
                  <p className="text-black font-medium text-xs md:text-sm  leading-normal text-center mt-1">
                    You want to Delete Gift
                  </p>
                </div>

                {/* ------ Fotter ------ */}
                <div className="flex justify-center items-center m-5">
                  <button
                    className="bg-[#18b66c] text-white font-semibold uppercase text-sm px-6 py-3 rounded-lg shadow hover:bg-[#1a965c] hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 mx-2"
                    type="button"
                    onClick={(e) => ConfirmDelete(SelectedUser)}
                  >
                    Yes
                  </button>
                  <button
                    className="bg-[#d31e1e] text-white font-semibold uppercase text-sm px-6 py-3 rounded-lg shadow hover:bg-[#cc1616] hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 mx-2"
                    type="button"
                    onClick={(e) => ClosePopUp()}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-20 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
};

export default Gift;
