import React, { useEffect, useState } from "react";
import { RiDeleteBin6Line } from "@react-icons/all-files/ri/RiDeleteBin6Line";
import axiosInstanceAuth from "../../apiInstances/axiosInstanceAuth";
import { MdOutlineLockReset } from "react-icons/md";
import { toast } from "react-toastify";
import useEncryption from "../../useEncryption/useEncryption";
import Pagination from "./Pagination";
import { Link, useNavigate } from "react-router-dom";

const UaerTable = () => {
  const [AllUsers, setAllUsers] = useState([]);
  const PageSize = 10;

  const { decryptData } = useEncryption();
  const Navigate = useNavigate();
  const [DeletePopUp, setDeletePopUp] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [Search, setSearch] = useState("");
  const [SelectedUsers, setSelectedUsers] = useState("");
  const firstPageIndex = (currentPage - 1) * PageSize;
  const [loading, setLoding] = useState(false);
  const lastPageIndex = firstPageIndex + PageSize;
  const currentData = AllUsers.slice(firstPageIndex, lastPageIndex);

  const getUserData = async () => {
    // setAllUsers([]);
    await axiosInstanceAuth
      .get(`/admin/viewUsers`)
      // .get(`/admin/viewUsers?page=${pageNumber}`)
      .then((res) => {
        const myData = decryptData(res?.data?.data);
        setAllUsers(myData?.data);
        // setTotalPages(myData?.totalpage)
        if (myData?.status.length > 0) {
          // setHasMorePages(true);
        } else {
        }
      })
      .catch((err) => {
        console.log("err --->", err);
      });
  };
  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    if (Search != "") {
      searching();
    } else {
      getUserData();
    }
  }, [Search]);

  const searching = () => {
    axiosInstanceAuth
      .post(`/admin/search`, { search: Search })
      .then((res) => {
        const myData = decryptData(res?.data?.data);
        setAllUsers(myData?.data);
      })
      .catch((err) => console.log(err));
  };

  const sortingData = (e) => {
    let type = e.target.textContent.toLowerCase();
    const sortName = [...AllUsers];
    if (type === "name") setAllUsers(sortName.sort());
    else {
      setAllUsers(sortName.reverse());
    }
  };

  const ConfirmDelete = (SelectedUsers) => {
    deleteUser(SelectedUsers);
  };

  const OpenPopUp = (email) => {
    setSelectedUsers(email);
    setDeletePopUp(true);
  };

  const ClosePopUp = () => {
    setDeletePopUp(false);
    setSelectedUsers("");
  };

  useEffect(() => {
    setLoding(true);
    setTimeout(() => {
      setLoding(false);
    }, 1000);
  }, []);

  // delete user**********************
  const deleteUser = async (email) => {
    await axiosInstanceAuth
      .post(`/admin/deleteUser/`, {
        email: email,
      })
      .then((res) => {
        console.log(res, "yyyyyy");
        const myData = decryptData(res?.data?.data);
        if (myData?.status) {
          ClosePopUp();
          getUserData();
          toast.success("Deleted Successfuly");
        } else {
          // toast.error(myData?.message);
        }
      })
      .catch((err) => {
        console.log("err --->", err);
      });
  };

  const BlockUser = async (email) => {
    await axiosInstanceAuth
      .post("admin/blockUser", {
        email: email,
      })
      .then((res) => {
        const myData = decryptData(res?.data?.data);

        if (myData?.status) {
          getUserData();
        } else {
          toast.error("Oops! Something went wrong");
        }
      })
      .catch((err) => {
        console.log("err --->", err);
      });
  };

  return (
    <>
      <div className="flex flex-col ml-3 lg:ml-64 xl:ml-96 md:mx-5 lg:mx-10 pt-10">
        <div className="-m-1.5 overflow-x-auto ">
          <div className=" min-w-full inline-block align-middle ">
            <div className="border backs1 rounded-lg divide-y divide-gray-200 border-gray-700 divide-gray-700">
              <div className="py-3 px-4">
                <div className="relative max-w-xs">
                  <label
                    for="hs-table-with-pagination-search"
                    className="sr-only"
                  >
                    Search
                  </label>
                  <input
                    type="search"
                    onChange={(e) => setSearch(e.target.value)}
                    name="Q"
                    id="hs-table-with-pagination-search"
                    className="p-3 pl-10 block w-full border-gray-200  outline-none rounded-md text-sm bg-slate-700 border-gray-700 text-gray-400"
                    placeholder="Search for User"
                  />
                  <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none pl-4">
                    <svg
                      className="h-3.5 w-3.5 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="overflow-hidden">
                <table className="min-w-full divide-y divide-gray-200 divide-gray-700">
                  <thead className="bg-gray-50 bg-gray-700">
                    <tr>
                      {/* <th scope="col" className="py-3 px-4 pr-0">
                        <div className="flex items-center h-5">
                          <input
                            id="hs-table-pagination-checkbox-all"
                            type="checkbox"
                            className="border-gray-200 rounded text-blue-600 bg-gray-800  "
                          />
                          <label
                            for="hs-table-pagination-checkbox-all"
                            className="sr-only"
                          >
                            Checkbox
                          </label>
                        </div>
                      </th> */}
                      <th scope="col" className="px-2 py-3 text-xs xl:text-sm">
                        <button
                          // onClick={sortingData}
                          className="flex gap-x-1 px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase"
                        >
                          {`ID (${AllUsers.length})`}
                          {/* <img
                          alt=""
                          src={sortIcon}
                          className="w-[.6rem] mt-[2px]"
                        ></img> */}
                        </button>
                      </th>
                      <th
                        scope="col"
                        onClick={sortingData}
                        className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase"
                      >
                        CreatedON
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase"
                      >
                        Last Login
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        onClick={sortingData}
                        className="px-6 py-3   text-xs font-medium text-gray-400 uppercase"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-600 ">
                    {currentData?.length > 0 &&
                      currentData.map((d, index) => (
                        <tr key={index}>
                          {/* <td className="py-3 pl-4">
                        <div className="flex items-center h-5">
                          <input
                            id="hs-table-pagination-checkbox-1"
                            type="checkbox"
                            className="border-gray-200 rounded text-blue-600 focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                          />
                          <label
                            for="hs-table-pagination-checkbox-1"
                            className="sr-only"
                          >
                            Checkbox
                          </label>
                        </div>
                      </td> */}
                          <td
                            // scope="row"
                            className="px-10 py-3.5 whitespace-nowrap text-sm text-gray-300 "
                          >
                            {firstPageIndex + index + 1}
                          </td>
                          <td className="px-8 py-3.5 whitespace-nowrap text-sm font-medium text-gray-800 ">
                            <div className="text-blue-500 bg-slate-900 rounded-lg py-2  cursor-pointer px-8 xl:px-2 ">
                              <Link to={`/userData/viewUser/${d?.id}`}>
                                {d?.firstname} {d?.lastname}
                              </Link>
                            </div>
                          </td>
                          <td className="px-6 py-3.5 whitespace-nowrap text-sm text-gray-300 ">
                            {d?.email}
                          </td>
                          <td className="px-6 py-3.5 whitespace-nowrap text-sm text-gray-300 ">
                            {d?.createdAt
                              .slice(0, 10)
                              .split("-")
                              .reverse()
                              .join("-")}
                          </td>
                          <td className="px-6 py-3.5 whitespace-nowrap  text-sm font-medium">
                            <div className=" whitespace-nowrap text-sm text-gray-300   ">
                              {d?.lastLogged
                                ? `${d?.lastLogged
                                    .slice(0, 10)
                                    .split("-")
                                    .reverse()
                                    .join("-")} (${d?.lastLogged.slice(
                                    11,
                                    16
                                  )})`
                                : "--"}
                            </div>
                          </td>
                          <td className="px-6  my-2   whitespace-nowrap  text-sm font-medium rounded-xl">
                            <div
                              className={`cursor-pointer bg-sky-950 py-2  text-center rounded-lg ${
                                d?.status === true
                                  ? " text-green-500 hover:text-green-600"
                                  : "text-red-500 hover:text-red-600"
                              }`}
                              onClick={() => BlockUser(d?.email)}
                            >
                              {d?.status === true ? "Unblock" : "Block"}
                            </div>
                          </td>
                          <td className="px-6 py-3.5 whitespace-nowrap text-sm text-gray-800 ">
                            <div className="flex justify-center gap-5">
                              <div>
                                <button
                                  type="submit"
                                  className=" text-red-700 py-1"
                                  onClick={() => {
                                    OpenPopUp(d?.email);
                                  }}
                                >
                                  <RiDeleteBin6Line size={23} />
                                </button>
                              </div>
                              <div>
                                <button
                                  type="submit"
                                  className="text-[#f47a5b] py-1 "
                                  onClick={() =>
                                    Navigate(`/user/reset/${d?.id}`)
                                  }
                                >
                                  <MdOutlineLockReset size={25} />
                                </button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>

              {/* pageinetion */}
              <div className="py-1 px-4 flex justify-center">
                <nav className="flex items-center space-x-2">
                  {/* next preves button */}

                  <div className="grid place-items-center ">
                    <Pagination
                      currentPage={currentPage}
                      totalCount={AllUsers.length}
                      pageSize={PageSize}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <<----- Change Status Pop Up ----->> */}
      {DeletePopUp ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[999999] outline-none focus:outline-none border ">
            <div className="relative min-w-[250px] max-w-[90%] mx-auto  my-10 shadow-black shadow-2xl">
              {/* ------ Content ------ */}
              <div className="border-0 rounded-lg shadow-2xl relative flex flex-col w-full bg-body-color outline-none focus:outline-none">
                {/* ------ Header ------ */}
                <div className="grid place-items-center place-content-end">
                  <button
                    className="bg-transparent border-0 text-black opacity-9 text-2xl font-normal outline-none focus:outline-none mx-3 my-2"
                    onClick={(e) => ClosePopUp()}
                  >
                    ×
                  </button>
                </div>
                {/* ------ Body ------ */}
                <div className="relative grid place-items-center px-6 md:px-10 py-3 flex-auto">
                  <h3 className="text-white font-semibold text-base md:text-lg  leading-relaxed text-center">
                    Are You Sure ?
                  </h3>
                  <p className="text-white font-medium text-xs md:text-sm  leading-normal text-center mt-1">
                    You want to Delete user.
                  </p>
                </div>

                {/* ------ Fotter ------ */}
                <div className="flex justify-center items-center m-5">
                  <button
                    className="bg-[#18b66c] text-white font-semibold uppercase text-sm px-6 py-3 rounded-lg shadow hover:bg-[#1a965c] hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 mx-2"
                    type="button"
                    onClick={(e) => ConfirmDelete(SelectedUsers)}
                  >
                    Yes
                  </button>
                  <button
                    className="bg-[#d31e1e] text-white font-semibold uppercase text-sm px-6 py-3 rounded-lg shadow hover:bg-[#cc1616] hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 mx-2"
                    type="button"
                    onClick={(e) => ClosePopUp()}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-20 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default UaerTable;
