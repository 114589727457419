import React, { lazy, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import ForgotPasswordForm from "./authentication/ForgotPasswordForm";
import LogInForm from "./authentication/LogInForm";
import ResetPasswordForm from "./authentication/ResetPasswordForm";
import NavBar from "./components/NavBar/NavBar";
import Users from "./components/Users/Users";
// import Content from "./components/Content/Content";
import UserData from "./components/User Data Management/UserData";

// const ViewBlogs =lazy(()=>import("./components/Blogs/ViewBlogs"));

import ViewBlogs from "./components/Blogs/ViewBlogs";

import AddBlog from "./components/Blogs/AddBlog";
import EditBlog from "./components/Blogs/EditBlog";
import DashBoard from "./components/DashBoard/DashBoard";
import Gift from "./components/Gift/Gift";
import EditGift from "./components/Gift/EditGift";
import ResetBtn from "./components/Users/ResetBtn";
// import Toggal from "./components/DashBoard/Toggal";
import "./App.css";
import NewUsers from "./components/Users/Newusers";
import ActiveUser from "./components/Users/ActiveUser";
import Card from "./components/Blogs/card";
import UaerTable from "./components/Users/UaerTable";

function App() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const getPath = useLocation().pathname.split("/")?.[2];

  useEffect(() => {
    const checkAuth = localStorage.getItem("Token");

    if (checkAuth === undefined || checkAuth === null) {
      if (getPath === "reset-password") {
      } else {
        navigate("/log-in");
      }
    }
  }, []);

  return (
    <>
      {pathname === "/log-in" ? null : pathname ===
        "/auth/forgot-password" ? null : getPath === "reset-password" ? null : (
          <NavBar />
        )}

      <Routes>
        <Route exact path="/" element={<DashBoard />} />
        <Route exact path="/Dashboard" element={<DashBoard />} />
        {/* <Route exact path="/Users" element={<Users />}/> */}
        <Route exact path="/UaerTable" element={<UaerTable />} />

        <Route exact path="/newusers" element={<NewUsers />} />
        <Route exact path="/activeusers" element={<ActiveUser />} />
        {/* <Route exact path="/Content" element={<Content />} /> */}
        <Route exact path="/blogs" element={<ViewBlogs />} />
        <Route exact path="/blogs/edit/:id" element={<EditBlog />} />
        <Route exact path="/blogs/add" element={<AddBlog />} />
        <Route exact path="/log-in" element={<LogInForm />} />
        <Route
          exact
          path="/auth/forgot-password"
          element={<ForgotPasswordForm />}
        />
        <Route
          exact
          path="/auth/reset-password/:id/:auth"
          element={<ResetPasswordForm />}
        />
        <Route exact path="/userData/viewUser/:id" element={<UserData />} />
        <Route exact path="/user/Gift/:id" element={<Gift />} />
        <Route exact path="/gift/edit" element={<EditGift />} />
        <Route exact path="/user/reset/:id" element={<ResetBtn />} />
        <Route exact path="/card/:id" element={<Card />} />
        {/* <Toggal/> */}
      </Routes>
    </>
  );
}

export default App;
