import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./NavBar.css";
import gftrLogo from "../../assets/Icons/gftr-black-box-transparent.svg";
import { MdSpaceDashboard } from "react-icons/md";
import { FaUserAlt, FaBlog } from "react-icons/fa";

const NavBar = () => {
  const location = useLocation();
  const { pathname } = location;

  const getPath = useLocation().pathname.split("/")?.[1];
  const navigate = useNavigate();

  const [menu, setMenu] = useState(false);

  const menubar = (e) => {
    setMenu(e.target.checked);
  };

  const [ConfirmationPopUp, setConfirmationPopUp] = useState(false);

  const ClosePopUp = () => {
    setConfirmationPopUp(false);
  };

  const ConfirmLogOut = () => {
    localStorage.clear();
    navigate("/log-in");
    setConfirmationPopUp(false);
  };

  const headerData = [
    {
      id: 0,
      pathname: "/Dashboard",
      pagename: "Dashboard",
      Title: "Dashboard",
    },

    {
      id: 1,
      pathname: "/UaerTable",
      pagename: "User",
      Title: "User",
    },
    {
      id: 3,
      pathname: "/blogs",
      pagename: "Blogs",
    },
  ];

  return (
    <>
      <div
        className=" bgnav min-h-screen fixed bg-gradient-to-t from-[#23233b] from-50% via-[#357177] via-80% to-[#f1886ed8] to-90%"
        onLoad={() => {
          pathname === "/"
            ? navigate("/Dashboard")
            : navigate(pathname.slice(1));
        }}
      >
        <div className="min-h-screen lg:block hidden overflow-hidden w-60 xl:w-80  relative">
          <div
            className="grid place-items-center text-xl xl:text-2xlm-10 cursor-pointer"
            onClick={() => navigate("/Dashboard")}
          >
            <img src={gftrLogo} alt="Logo" />
          </div>
          <div className="flex h-screen flex-col justify-start mt-5">
            <div>
              <ul className="flex flex-col gap-2 space-y-2 tracking-wide mt-32 text-right">
                <div>
                  <li className="min-w-max effect-h flex flex-col justify-start">
                    <Link to="/Dashboard">
                      <div className="flex items-center gap-4 flex-row justify-start text-xl xl:text-2xl py-2 px-5 font-semibold text-white hover:bg-gradient-to-r from-black">
                        <MdSpaceDashboard size={25} />
                        Dashboard
                      </div>
                    </Link>
                  </li>
                </div>
                <div>
                  <li className="min-w-max effect-h flex flex-col justify-start">
                    <Link to="/UaerTable">
                      <div className="flex flex-row gap-4 justify-start text-xl xl:text-2xl py-2 px-5 font-semibold text-white hover:bg-gradient-to-r from-black">
                        <FaUserAlt size={23} />
                        User
                      </div>
                    </Link>
                  </li>
                </div>
                <div>
                  <li className="min-w-max effect-h flex flex-col justify-start">
                    <Link to="/blogs">
                      <div className="flex flex-row  gap-4 justify-start text-xl xl:text-2xl py-2 px-5 font-semibold text-white hover:bg-gradient-to-r from-black">
                        <FaBlog size={25} />
                        Blogs
                      </div>
                    </Link>
                  </li>
                </div>

                <li className="min-w-max effect-h px-5">
                  <div class="mx-auto flex mt-64 lg:mt-[268px] xl:mt-80 max-w-screen-lg items-center justify-center">
                    <div class="h-[60px] w-[150px] rounded-full bg-gradient-to-r from-[#F47a5b] via-[#4caeb8] to-yellow-500 p-1">
                      <div class="flex h-[52px] w-[142px] items-center justify-center rounded-full bg-[#000000] hover:bg-black back cursor-pointer">
                        <h2
                          class="font-black text-white "
                          onClick={(e) => setConfirmationPopUp(true)}
                        >
                          Log Out
                        </h2>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* phone size */}
      <nav className="lg:hidden bg-gradient-to-r from-[#46717e] to-[#11333b] rounded-xl flex justify-between items-center">
        <input
          type="checkbox"
          id="nav-toggle"
          checked={menu}
          onChange={menubar}
        />
        <img
          className="max-w-[100px] px-2 py-5 mx-3 cursor-pointer "
          src={gftrLogo}
          alt="Logo"
          onClick={() => navigate("/")}
        />
        <ul
          className="links list-unstyled flex items-center"
          onClick={() => setMenu(false)}
        >
          {headerData?.map((data, index) => (
            <li key={index} >
              <Link
                to={data.pathname}
                className={`/${getPath}` === data.pathname ? "active" : ""}
              >
                {data.pagename}
              </Link>
            </li>
          ))}
          <li className="pt-12">
            <p
              className="cursor-pointer py-2 px-4 bg-gradient-to-r from-[#ff8989] to-[#a03911] border-white text-white rounded-xl"
              onClick={(e) => setConfirmationPopUp(true)}
            >
              Log Out
            </p>
          </li>
        </ul>
        <label htmlFor="nav-toggle" className="icon-burger cursor-pointer mx-3">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </label>
      </nav>

      {/* <<----- Log Out Pop Up ----->> */}

      {ConfirmationPopUp ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[999999]  outline-none focus:outline-none border ">
            <div className="relative min-w-[250px] max-w-[90%] mx-auto  my-10 shadow-black shadow-2xl">
              {/* ------ ContentManagement ------ */}
              <div className="border-0 rounded-lg shadow-2xl relative flex flex-col w-full bg-slate-500 outline-none focus:outline-none">
                {/* ------ Header ------ */}
                <div className="grid place-items-center place-ContentManagement-end">
                  <button
                    className="bg-transparent border-0 text-black opacity-9 text-2xl font-normal outline-none focus:outline-none mx-3 my-2"
                    onClick={(e) => ClosePopUp()}
                  >
                    ×
                  </button>
                </div>
                {/* ------ Body ------ */}
                <div className="relative grid place-items-center px-6 md:px-10 py-3 flex-auto">
                  <h3 className="text-black font-semibold text-base md:text-lg  leading-relaxed text-center">
                    Are You Sure ?
                  </h3>
                  <p className="text-black font-medium text-xs md:text-sm  leading-normal text-center mt-1">
                    You want to Log Out
                  </p>
                </div>

                {/* ------ Fotter ------ */}
                <div className="flex justify-center items-center m-5">
                  <button
                    className="bg-[#18b66c] text-white font-semibold uppercase text-sm px-6 py-3 rounded-lg shadow hover:bg-[#1a965c] hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 mx-2"
                    type="button"
                    onClick={(e) => ConfirmLogOut()}
                  >
                    Yes
                  </button>
                  <button
                    className="bg-[#d31e1e] text-white font-semibold uppercase text-sm px-6 py-3 rounded-lg shadow hover:bg-[#cc1616] hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 mx-2"
                    type="button"
                    onClick={(e) => ClosePopUp()}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-20 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default NavBar;
