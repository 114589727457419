import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstanceAuth from "../../apiInstances/axiosInstanceAuth";
import { BACKEND_BASE_URL } from "../../apiInstances/baseurl";
import useEncryption from "../../useEncryption/useEncryption";
import editIcon from "../../assets/Images/edit.png";
import deleteIcon from "../../assets/Images/delete.png";
import { RiDeleteBin6Line } from "@react-icons/all-files/ri/RiDeleteBin6Line";
import GridLoader from "react-spinners/GridLoader";
import { FaEdit } from "react-icons/fa";
const ViewBlogs = () => {
  const navigate = useNavigate();
  const { decryptData } = useEncryption();
  const [loading, setLoding] = useState(false);

  useEffect(() => {
    setLoding(true);
    setTimeout(() => {
      setLoding(false);
    }, 1000);
  }, []);

  useEffect(() => {
    getBlogsData();
  }, []);

  const [DeletePopUp, setDeletePopUp] = useState(false);
  const [SelectedBlog, setSelectedBlog] = useState("");
  const [AllBlogsData, setAllBlogsData] = useState([]);
  const OpenPopUp = (id) => {
    setSelectedBlog(id);
    setDeletePopUp(true);
  };

  const ClosePopUp = () => {
    setDeletePopUp(false);
    setSelectedBlog("");
  };

  const ConfirmDelete = (SelectedBlog) => {
    deleteBlog(SelectedBlog);
  };

  const getBlogsData = async () => {
    await axiosInstanceAuth
      .get("admin/viewBlog")
      .then((res) => {
        const myData = decryptData(res?.data?.data);
        console.log("🚀 ~ file: ViewBlogs.jsx:50 ~ .then ~ myData:", myData)
        setAllBlogsData(myData?.data);
        // console.log(".....................myData..............................", myData.data);

        if (myData?.status) {
        } else {
          toast.error(myData?.message);
        }
      })
      .catch((err) => {
        console.log("err --->", err);
      });
  };

  const deleteBlog = async (SelectedBlog) => {
    await axiosInstanceAuth
      .delete(`admin/deleteblog/${SelectedBlog}`)
      .then((res) => {
        const myData = decryptData(res?.data?.data);

        if (myData?.status) {
          ClosePopUp();
          getBlogsData();
          toast.success("Blog Deleted Successfuly");
        } else {
          toast.error(myData?.message);
        }
      })
      .catch((err) => {
        console.log("err --->", err);
      });
  };

  // active deactive blogs
  const Active = async (id) => {
    await axiosInstanceAuth
      .get(`/admin/status/${id}`)
      .then((res) => {
        const myData = decryptData(res?.data?.data);

        // console.log(myData, "+++++------++++");

        if (myData?.status) {
          getBlogsData();
        } else {
          toast.error("Oops! Something went wrong");
        }
      })
      .catch((err) => {
        console.log("err --->", err);
      });
  };
  return (
    <>
      <div className="lg:ml-64 mb-10 py-10 flex justify-center overflow-scroll ml-3 xl:ml-80 md:mx-5 pt-20 ">
        {/* {loading ? (
        <GridLoader
          loading={loading}
          color={"#F37A5B"}
          size={18}
          className=" mt-44"
        />
      ) : ( */}
        <div>
          <div className="grid place-items-end m-5">
            <button
              className="w-40 bg-[#000000] text-white border hover:border-[black] font-semibold text-sm px-6 py-3 rounded-lg shadow hover:bg-slate-700 hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 mx-2"
              onClick={(e) => navigate("/blogs/add")}
            >
              Add New Blog
            </button>
          </div>
          <div className="grid md:grid md:grid-cols-3 xl:grid-cols-4 sm:grid-cols-2 mx-7 xl:mx-10 gap-3 xl:gap-10">
            {AllBlogsData?.length > 0 &&
              AllBlogsData?.map((i, index) => (
                <div
                  key={index}
                  className="max-w-sm bg-gradient-to-r from-[#2f2f49] to-[#191a49] hover:bg-slate-100  rounded-lg shadow-2xl "
                >
                  {/* <div className="bg-cyan-400 rounded-full w-5 h-5 ml-2 text-center">
                {index + 1}
              </div> */}
                  <Link to={`/card/${i?._id}`}>
                    <img
                      className="rounded-t-lg w-full h-48 "
                      src={`${BACKEND_BASE_URL}/${i?.image}`}
                      alt={i?.title}
                    />
                  </Link>
                  <div className=" p-3 xl:p-5">
                    <Link to={`/card/${i?._id}`}>
                      <div className="my-3 text-lg text-center font-bold text-slate-300 mx-5 min-h-auto">
                        <h5>
                          {i?.title.slice(0,18)} 

                        </h5>
                        <h5 className="mb-10">
                          {i?.title_1}

                        </h5>
                        {/* {i?.title_1} */}
                      </div>


                    </Link>
                    {/* <p
                      className=" mb-3 xl:mb-5 text-sm  font-normal text-slate-300 "
                      // title={i?.content}
                    >
                     {i?.content.length <= 60
                        ? i?.content
                        : i?.content.substring(0, 60) + "..."} 
                      <div
                        className="inline-flex  text-xs text-center text-orange  cursor-pointer"
                        onClick={(e) => navigate(`/card/${i?._id}`)}
                      >
                        .....Read More
                      </div>
                    </p>  */}

                    <div className="flex  justify-between items-center ">
                      <div className="flex gap-2">
                        <div>
                          <button
                            className=" items-center text-sm font-medium text-center text-slate-300"
                            onClick={(e) => navigate(`/blogs/edit/${i?._id}`)}
                          >
                            <FaEdit size={22} />
                            {/* <img src={editIcon} alt="edit" /> */}
                          </button>
                        </div>
                        <div>
                          <button
                            className=" items-center text-sm font-medium text-center text-red-700  "
                            onClick={() => {
                              OpenPopUp(i?._id);
                            }}
                          >
                            <RiDeleteBin6Line size={23} />
                          </button>
                        </div>
                      </div>
                      <div>
                        <div
                          className={`text-sm xl:text-md cursor-pointer rounded-lg  text-center ${i?.status === true
                            ? "text-green-500  "
                            : "text-red-500 "
                            }`}
                          onClick={() => Active(i?._id)}
                        >
                          {i?.status === true ? "Active" : "Deactive"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>

          {/* <<----- Change Status Pop Up ----->> */}
          {DeletePopUp ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[999999]  outline-none focus:outline-none border ">
                <div className="relative min-w-[250px] max-w-[90%] mx-auto  my-10 shadow-black shadow-2xl">
                  {/* ------ Content ------ */}
                  <div className="border-0 rounded-lg shadow-2xl relative flex flex-col w-full bg-slate-500 outline-none focus:outline-none">
                    {/* ------ Header ------ */}
                    <div className="grid place-items-center place-content-end">
                      <button
                        className="bg-transparent border-0 text-black opacity-9 text-2xl font-normal outline-none focus:outline-none mx-3 my-2"
                        onClick={(e) => ClosePopUp()}
                      >
                        ×
                      </button>
                    </div>
                    {/* ------ Body ------ */}
                    <div className="relative grid place-items-center px-6 md:px-10 py-3 flex-auto">
                      <h3 className="text-black font-semibold text-base md:text-lg  leading-relaxed text-center">
                        Are You Sure ?
                      </h3>
                      <p className="text-black font-medium text-xs md:text-sm  leading-normal text-center mt-1">
                        You want to Delete this Blog
                      </p>
                    </div>

                    {/* ------ Fotter ------ */}
                    <div className="flex justify-center items-center m-5">
                      <button
                        className="bg-[#18b66c] text-white font-semibold uppercase text-sm px-6 py-3 rounded-lg shadow hover:bg-[#1a965c] hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 mx-2"
                        type="button"
                        onClick={(e) => ConfirmDelete(SelectedBlog)}
                      >
                        Yes
                      </button>
                      <button
                        className="bg-[#d31e1e] text-white font-semibold uppercase text-sm px-6 py-3 rounded-lg shadow hover:bg-[#cc1616] hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 mx-2"
                        type="button"
                        onClick={(e) => ClosePopUp()}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-20 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ViewBlogs;