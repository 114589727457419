import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstanceAuthFormData from "../../apiInstances/axiosInstanceAuthFormData";
import useEncryption from "../../useEncryption/useEncryption";
import addIcon from "../../assets/Images/add-image1.png";
import defaultBlog from "../../assets/Images/defaultBlog.png";
import JoditEditor from "jodit-react";
import "./editor-styles.css";

const AddBlog = () => {
  const navigate = useNavigate();
  const { decryptData } = useEncryption();
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [BlogsData, setBlogsData] = useState({
    title: "",
    title_1: "",
    content: "",
  });
  console.log("🚀 ~ file: AddBlog.jsx:21 ~ AddBlog ~ BlogsData:", BlogsData)
  const [BlogPhoto, setBlogPhoto] = useState(null);
  const [BlogPhotoShow, setBlogPhotoShow] = useState(null);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setBlogPhotoShow(URL.createObjectURL(event.target.files[0]));
    }
    let file = event.target.files[0];
    setBlogPhoto(file);
  };

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setBlogsData({
      ...BlogsData,
      [name]: value,
    });
  };

  const AddNewBlog = async () => {
    try {
      const formData = new FormData();
      formData.append("title", BlogsData?.title);
      formData.append("title_1", BlogsData?.title_1);
      formData.append("content", BlogsData?.content);
      formData.append("file", BlogPhoto);

      await axiosInstanceAuthFormData
        .post(`admin/addBlog`, formData)
        .then((res) => {
          const myData = decryptData(res?.data?.data);

          if (myData?.status) {
            toast.success("Blog Add Successfuly");
            navigate(`/blogs`);
          } else {
            toast.error(myData.message);
          }
        })
        .catch((err) => {
          console.log("------>> Error", err);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const inputTextTitle =
    "min-w-[15%] monospace mr-5 text-lg font-bold";

  const inputTextField =
    "w-[75%] border outline-none text-sm px-4 py-2 rounded-md border-none text-slate-400 bg-slate-700";

  const textAreaField =
    "mt-1 border outline-none text-sm px-2 py-1 rounded-md font-normal leading-5 w-[75%] border-none text-slate-400 bg-slate-700";

  return (
    <>
      <div className="ml-3 lg:ml-64 xl:ml-96 md:mx-5 lg:mx-10 pt-20">
        <div className="container mx-auto px-4 md:px-24 pt-20 bg-gradient-to-r from-[#2f2f49] to-[#191a49] rounded-xl">
          <div className="mx-auto my-5">
            <div className="container mx-auto flex justify-center items-center">
              <div className="relative">
                {BlogPhotoShow ? (
                  <img
                    src={BlogPhotoShow}
                    alt="Blog"
                    className="w-60 rounded-md object-cover"
                  />
                ) : (
                  <img
                    src={defaultBlog}
                    alt="Default"
                    className="w-60 rounded-md object-cover"
                  />
                )}
                <label
                  className="absolute -bottom-3 -right-3 bg-gray-300 shadow-xl rounded-full p-2 cursor-pointer"
                  htmlFor="BlogImg"
                >
                  <img src={addIcon} alt="Add" className="w-5" />
                </label>
              </div>
              <input
                className="hidden"
                id="BlogImg"
                type="file"
                onChange={onImageChange}
              />
            </div>
          </div>

          <div className="mx-auto py-5">
            <div className="flex flex-col md:flex-row items-center justify-center leading-10 lg:leading-tight mb-4">
              <p className={`${inputTextTitle} !text-white`}>Title 1</p>
              <input
                type="text"
                className={inputTextField}
                placeholder="e.g. Make Giving Easier"
                value={BlogsData?.title}
                name="title"
                onChange={onChangeInput}
              />
            </div>
            <div className="flex flex-col md:flex-row items-center justify-center leading-10 lg:leading-tight mb-4">
              <p className={`${inputTextTitle} !text-white`}>Title 2</p>
              <input
                type="text"
                className={inputTextField}
                placeholder="e.g. Make Giving Easier"
                value={BlogsData?.title_1}
                name="title_1"
                onChange={onChangeInput}
              />
            </div>

            <div className="flex flex-col md:flex-row items-center justify-center ">
              <p className={`${inputTextTitle} text-white`}>Content</p>
              <div className=" mx-10 rounded-md lg:w-[1200px] sm:w-[380px] disabled text-black">
                <JoditEditor
                  ref={editor}
                  value={BlogsData?.content}
                  onChange={(newContent) =>
                    setBlogsData({ ...BlogsData, content: newContent })
                  }
                />

                {/* {content} */}
              </div>
            </div>
          </div>

          <div className="mx-auto container text-center py-4">
            <button
              className="bg-gradient-to-r from-[#434366] to-[#0c0d1a] text-white hover:text-slate-400 rounded-full py-2 px-6"
              onClick={AddNewBlog}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddBlog;
