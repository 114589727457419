import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstanceAuth from "../../apiInstances/axiosInstanceAuth";
import { BACKEND_BASE_URL } from "../../apiInstances/baseurl";
import useEncryption from "../../useEncryption/useEncryption";
import "./editor-styles.css";
import GridLoader from "react-spinners/GridLoader";
import { BiArrowBack } from "react-icons/bi";


const Card = () => {
  const { decryptData } = useEncryption();
  const [loading, setLoding] = useState(false);
  let { id } = useParams();

  useEffect(() => {
    setLoding(true);
    setTimeout(() => {
      setLoding(false);
    }, 1000);
  }, []);

  useEffect(() => {
    getBlogsData();
  }, []);

  const [AllBlogsData, setAllBlogsData] = useState([]);

  const getBlogsData = async () => {
    await axiosInstanceAuth
      .get("admin/viewBlog")
      .then((res) => {
        const myData = decryptData(res?.data?.data);
        setAllBlogsData(myData?.data);
        // console.log(".....................myData..............................", myData.data);

        if (myData?.status) {
        } else {
          toast.error(myData?.message);
        }
      })
      .catch((err) => {
        console.log("err --->", err);
      });
  };

  const result = AllBlogsData?.find((cardData) => cardData._id === id);
  // console.log("result", result);

  return (
    <>
      <div className="pt-32 ">
        <div className="mx-2 lg:ml-64 xl:ml-96 pt-10 pb-10 md:mx-5 lg:mx-10  text-center bg-gradient-to-r from-[#2f2f49] to-[#191a49] rounded-xl">
          <Link to="/blogs">
            <div className="ml-5 lg:ml-10 xl:ml-10 md:ml-10 mb-5 text-white text-3xl flex gap-5">
              <BiArrowBack />
              Back
            </div>
          </Link>
          <div className="flex justify-center ">
            <img
              className="rounded-t-lg h-96 rounded-2xl "
              src={`${BACKEND_BASE_URL}/${result?.image}`}
              alt={result?.title}
            />
          </div>
          <div className="border-2 xl:mx-60 lg:mx-28 md:mx-28 mx-10 mt-10 border-none bg-white rounded-2xl">
            <h5 className="mb-2  p-2 font-bold tracking-widest text-4xl">
              {result?.title} : <br />
              {result?.title_1}

            </h5>
            {/* <h5 className="mb-2 text-2xl p-2 font-bold tracking-tight  ">
              {result?.title_1} : 
            </h5> */}
            <p className="mb-3 pb-5 font-normal text-justify mx-5  ">
              {/* {result?.content} */}
              <div
                className="mb-3 pb-5 font-normal text-justify mx-5 editor-style"
                dangerouslySetInnerHTML={{ __html: result?.content }}
              />
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
