import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstanceAuth from "../../apiInstances/axiosInstanceAuth";
import { BACKEND_BASE_URL } from "../../apiInstances/baseurl";
import useEncryption from "../../useEncryption/useEncryption";
import { useSearchParams } from "react-router-dom";

const EditBlog = () => {
  const navigate = useNavigate();
  const { decryptData } = useEncryption();

  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");
  const folderid = searchParams.get("folderid");
  const [UpadatePopUp, setUpadatePopUp] = useState(false);
  const ConfirmUpadete = () => {
    editThisBlog();
    navigate(`/user/Gift/${folderid}`);
  };

  const OpenPopUp = () => {
    setUpadatePopUp(true);
  };

  const ClosePopUp = () => {
    setUpadatePopUp(false);
  };

  const [GiftData, setGiftData] = useState({
    title: "",
    url: "",
    price: "",
    notes: "",
  });
  useEffect(() => {
    getGiftData();
  }, []);

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setGiftData({
      ...GiftData,
      [name]: value,
    });
  };

  const getGiftData = async () => {
    try {
      const res = await axiosInstanceAuth.post(`/admin/viewgift/${folderid}`, {
        id: id,
      });

      const myData = decryptData(res?.data?.data);
      const final = myData?.giftdata?.formdata[0];
      setGiftData({
        ...GiftData,
        title: final?.title,
        url: final?.webViewLink,
        price: final?.price,
        notes: final?.notes,
      });
    } catch (err) {
      console.log("err --->", err);
    }
  };

  const data = {
    id: id,
    title: GiftData?.title,
    url: GiftData?.url,
    price: GiftData?.price,
    notes: GiftData?.notes,
  };
  const editThisBlog = async () => {
    try {
      await axiosInstanceAuth
        .post(`/admin/editgift/${folderid}`, { data })
        .then((res) => {
          const myData = decryptData(res?.data?.data);
          if (myData?.status) {
            // navigate("/Users");
            toast.success(myData?.message);
          } else {
            toast.error(myData?.message);
          }
        })
        .catch((err) => console.log(err));
    } catch (error) {
      console.error(error);
    }
  };

  const inputTextTitle =
    "min-w-[15%] monospace mr-5 text-slate-300 text-lg font-bold  ";

  const inputTextField = "outline-none w-[75%] border text-sm px-4 py-2 rounded-md bg-slate-700 border-none text-slate-400";

  const textAreaField =
    "mt-1 outline-none text-sm px-2 py-1 rounded-md font-normal leading-5 w-[75%] border-none text-slate-400 bg-slate-700";

  return (
    <div className="ml-3 lg:ml-64 xl:ml-96 md:mx-5 lg:mx-10 pt-20">
      <div className="container mx-auto px-4 md:px-24 pt-20 pb-10 bg-gradient-to-r from-[#2f2f49] to-[#191a49] rounded-xl ">

        <div className="mx-auto py-5 ">
          <div className="flex flex-col md:flex-row items-center justify-center leading-10 lg:leading-tight mb-4">
            <p className={inputTextTitle}>Gift Name</p>
            <input
              type="text"
              className={inputTextField}
              placeholder="e.g. Make Giving Easier"
              value={GiftData?.title}
              name="title"
              onChange={onChangeInput}
            />
          </div>

          <div className="flex flex-col md:flex-row items-center justify-center leading-10 lg:leading-tight mb-4">
            <p className={inputTextTitle}>URL</p>
            <input
              type="text"
              className={inputTextField}
              placeholder="e.g. Make Giving Easier"
              value={GiftData?.url}
              name="url"
              onChange={onChangeInput}
            />
          </div>

          <div className="flex flex-col md:flex-row items-center justify-center leading-10 lg:leading-tight mb-4">
            <p className={inputTextTitle}>Gift Price</p>
            <input
              type="text"
              className={inputTextField}
              placeholder="e.g. Make Giving Easier"
              value={GiftData?.price}
              name="price"
              onInput={(e) =>
                (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
              }
              onChange={onChangeInput}
            />
          </div>

          <div className="flex flex-col md:flex-row items-center justify-center leading-10 lg:leading-tight mb-4">
            <p className={inputTextTitle}>Notes</p>
            <textarea
              rows={3}
              type="text"
              className={textAreaField}
              placeholder="e.g. That one person in your life who, year after year, not only remembers your birthday, but also somehow has the time, creativity & forethought to find, buy and deliver the perfect gift..."
              value={GiftData?.notes}
              name="notes"
              onChange={onChangeInput}
            />
          </div>
        </div>

        <div className="mx-auto container text-center py-4 pb-5">
          <button
            className="py-2 px-6 bg-gradient-to-r from-[#434366] to-[#0c0d1a] text-white hover:text-slate-400 rounded-full  box-border "
            onClick={() => OpenPopUp()}
          >
            Submit
          </button>
        </div>
      </div>

      {UpadatePopUp ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[999999]  outline-none focus:outline-none border ">
            <div className="relative min-w-[250px] max-w-[90%] mx-auto  my-10 shadow-black shadow-2xl">
              <div className="border-0 rounded-lg shadow-2xl relative flex flex-col w-full bg-slate-500 outline-none focus:outline-none">
                <div className="grid place-items-center place-content-end">
                  <button
                    className="bg-transparent border-0 text-black opacity-9 text-2xl font-normal outline-none focus:outline-none mx-3 my-2"
                    onClick={(e) => ClosePopUp()}
                  >
                    ×
                  </button>
                </div>
                <div className="relative grid place-items-center px-6 md:px-10 py-3 flex-auto">
                  <h3 className="text-black font-semibold text-base md:text-lg  leading-relaxed text-center">
                    Are You Sure ?
                  </h3>
                  <p className="text-black font-medium text-xs md:text-sm  leading-normal text-center mt-1">
                    You want to Edit Gift
                  </p>
                </div>
                <div className="flex justify-center items-center m-5">
                  <button
                    className="bg-[#18b66c] text-white font-semibold uppercase text-sm px-6 py-3 rounded-lg shadow hover:bg-[#1a965c] hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 mx-2"
                    type="button"
                    onClick={() => ConfirmUpadete()}
                  >
                    Yes
                  </button>
                  <button
                    className="bg-[#d31e1e] text-white font-semibold uppercase text-sm px-6 py-3 rounded-lg shadow hover:bg-[#cc1616] hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 mx-2"
                    type="button"
                    onClick={(e) => ClosePopUp()}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-20 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
};

export default EditBlog;
