import React, { useEffect, useState } from "react";
import useEncryption from "../../useEncryption/useEncryption";
import axiosInstanceAuth from "../../apiInstances/axiosInstanceAuth";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Pagination from "../Users/Pagination";
import { RiDeleteBin6Line } from "@react-icons/all-files/ri/RiDeleteBin6Line";

const UserData = () => {
  const { decryptData } = useEncryption();
  const [active, setActive] = useState(1);
  // const [pageNumber, setPageNumber] = useState(1);

  let { id } = useParams();
  useEffect(() => {
    getUserData();
    getUsercustom();
    getUserFriend();
  }, []);
  // useEffect(() => {
  //   getUserData();
  //   getUsercustom();
  //   getUserFriend();
  // }, [pageNumber]);

  const [DeletePopUp, setDeletePopUp] = useState(false);
  const [SelectedUser, setSelectedUser] = useState("");
  const [SelecteCustom, setSelecteCustom] = useState("");
  const [SelecteFriend, setSelecteFriend] = useState("");
  const [AllUsers, setAllUsers] = useState([]);
  const [AllFriend, setAllFriend] = useState([]);
  const [AllCustom, setAllCustom] = useState([]);

  // pagination
  const PageSize = 6;
  const [currentPage, setCurrentPage] = useState(1);
  const firstPageIndex = (currentPage - 1) * PageSize;
  const lastPageIndex = firstPageIndex + PageSize;

  const currentData = Array.isArray(AllUsers)
    ? AllUsers.slice(firstPageIndex, lastPageIndex)
    : [];
  const currentFriendData = Array.isArray(AllFriend)
    ? AllFriend.slice(firstPageIndex, lastPageIndex)
    : [];
  const currentCustomData = Array.isArray(AllCustom)
    ? AllCustom.slice(firstPageIndex, lastPageIndex)
    : [];

  // const currentData = AllUsers.slice(firstPageIndex, lastPageIndex);
  // const currentFriendData = AllFriend.slice(firstPageIndex, lastPageIndex);
  // const currentCustomData = AllCustom.slice(firstPageIndex, lastPageIndex);

  // over pagination

  const ConfirmDelete = (SelecteCustom, SelectedUser, SelecteFriend) => {
    deleteUser(SelectedUser);
    deleteCustom(SelecteCustom);
    deleteFriend(SelecteFriend);
  };

  const OpenPopUp = (id) => {
    setSelectedUser(id);
    setSelecteCustom(id);
    setSelecteFriend(id);
    setDeletePopUp(true);
  };
  const ClosePopUp = () => {
    setDeletePopUp(false);
    setSelectedUser("");
    setSelecteCustom("");
    setSelecteFriend("");
  };

  // *************************************************show gift folder
  const getUserData = async () => {
    await axiosInstanceAuth
      .get(`/admin/allgift/${id}`)
      .then((res) => {
        const myData = decryptData(res?.data?.data);
        // console.log(myData, "........................>>>>");
        setAllUsers(myData?.data.allgift);

        if (myData?.status) {
        } else {
          // toast.error(myData?.message);
        }
      })
      .catch((err) => {
        console.log("err --->", err);
      });
  };

  //**************************************************** */ show event
  const getUsercustom = async () => {
    await axiosInstanceAuth
      .get(`/admin/userevent/${id}`)
      .then((res) => {
        const myData = decryptData(res?.data?.data);
        setAllCustom(myData?.data);
        console.log(myData, "kkkkkkkkkkkkkkkkkkkkkkkk");
        if (myData?.status) {
        } else {
          // toast.error(myData?.message);
        }
      })
      .catch((err) => {
        console.log("err --->", err);
      });
  };

  // *************************************************show Friend folder
  const getUserFriend = async () => {
    await axiosInstanceAuth
      .get(`/admin/friends/${id}`)
      .then((res) => {
        const myData = decryptData(res?.data?.data);
        setAllFriend(myData?.data);

        if (myData?.status) {
        } else {
        }
      })
      .catch((err) => {
        console.log("err --->", err);
      });
  };

  // **************************delete gift folder
  const deleteUser = async (folder_name) => {
    await axiosInstanceAuth
      .get(`/admin/deleteallgift/${SelectedUser}`)
      .then((res) => {
        const myData = decryptData(res?.data?.data);

        if (myData?.status) {
          ClosePopUp();
          getUserData();
        } else {
        }
      })
      .catch((err) => {
        console.log("err --->", err);
      });
  };

  // *****************************************delete event
  const deleteCustom = async (folder_name) => {
    await axiosInstanceAuth
      .post(`/admin/deleteevent/${id}`, { id: SelecteCustom })
      .then((res) => {
        const myData = decryptData(res?.data?.data);

        if (myData?.status) {
          ClosePopUp();
          getUsercustom();
        } else {
        }
      })
      .catch((err) => {
        console.log("err --->", err);
      });
  };

  // *****************************************delete Friend
  const deleteFriend = async (folder_name) => {
    await axiosInstanceAuth
      .post(`/admin/deletefrd/${id}`, { id: SelecteFriend })
      .then((res) => {
        const myData = decryptData(res?.data?.data);
        if (myData?.status) {
          ClosePopUp();
          getUserFriend();
          toast.success("Deleted Successfuly");
        } else {
          // toast.error(myData?.message);
        }
      })
      .catch((err) => {
        console.log("err --->", err);
      });
  };
  // ***************************************sorting data

  const sortingData = (e) => {
    let type = e.target.textContent.toLowerCase();
    const sorted = [...AllUsers, ...AllCustom].sort((a, b) =>
      a[type] > b[type] ? 1 : b[type] > a[type] ? -1 : 0
    );
    setAllCustom(sorted);
    setSelectedUser(sorted);
  };

  const UserSidebarStyle =
    "py-4 px-8 my-8 hover:bg-[#4BADB8] text-white rounded-full cursor-pointer capitalize  shadow-xl";
  const ActiveUserSidebarStyle =
    "py-4 px-8 my-8 bg-[#4BADB8] rounded-full cursor-pointer capitalize text-white shadow-xl";

  const handleClick = (current) => () => {
    setActive(current);
  };

  // switch button for public and private
  const handleToggle = async (id) => {
    await axiosInstanceAuth.post(`/admin/editfolder/${id}`);
    getUserData();
  };

  // sarchbar start+++++++++++++++++++++++++++++++

  const [searchGift, setSearchGift] = useState("");
  const [searchFriend, setSearchFriend] = useState("");
  const [searchEvent, setSearchEvent] = useState("");

  // pagination =================================================================
  // const handlePrevPage = () => {
  //   setPageNumber(prevPageNumber => prevPageNumber - 1);
  // };

  // const handleNextPage = () => {
  //   // Check if there are more pages to fetch
  //   if (AllUsers.length > 0) {
  //     setPageNumber(prevPageNumber => prevPageNumber + 1);
  //   }
  // };

  return (
    <>
      <div className="ml-0 lg:ml-64 ">
        <div className="container mx-auto px-4  py-10">
          <div className="flex justify-center text-black px-5 font-bold text-base lg:text-lg text-center">
            <span
              className={`${
                active === 1
                  ? `${ActiveUserSidebarStyle} border border-l-[#4BADBB] border-t-[#4BADBB] border-b-[#4BADBB] rounded-tr-none rounded-br-none`
                  : `${UserSidebarStyle} border border-l-[#4BADBB] border-tr-[#4BADBB] border-t-[#4BADBB] border-b-[#4BADBB] rounded-tr-none rounded-br-none`
              }`}
              onClick={handleClick(1)}
            >
              Gift Lists
            </span>
            <span
              className={`${
                active === 2
                  ? `${ActiveUserSidebarStyle}  border border-r-[#4BADBB] border-l-[#4BADBB] border-t-[#4BADBB] border-b-[#4BADBB] rounded-tl-none rounded-bl-none rounded-tr-none rounded-br-none`
                  : `${UserSidebarStyle} border  border-[#4BADBB] border-l-0 border-r-0 !rounded-[0]`
              }`}
              onClick={handleClick(2)}
            >
              Friends
            </span>
            <span
              className={`${
                active === 3
                  ? `${ActiveUserSidebarStyle} border border-r-[#4BADBB] border-t-[#4BADBB] border-b-[#4BADBB] rounded-tl-none rounded-bl-none`
                  : `${UserSidebarStyle} border border-r-[#4BADBB] border-t-[#4BADBB] border-b-[#4BADBB] rounded-tl-none rounded-bl-none`
              }`}
              onClick={handleClick(3)}
            >
              Custom Event
            </span>
          </div>
          {/* <<----- User Data ----->> */}
          <div className="relative overflow-x-auto shadow-2xl sm:rounded-lg">
            {active === 1 && (
              <>
                <div className="">
                  <div className="flex justify-center items-center mb-5">
                    <div className="relative text-gray-600 focus-within:text-gray-400">
                      <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                        <button
                          type="submit"
                          className="p-1 focus:outline-none focus:shadow-outline"
                        >
                          <svg
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            className="w-6 h-6 text-gray-400"
                          >
                            <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                          </svg>
                        </button>
                      </span>
                      <input
                        type="search"
                        name="q"
                        className="text-lg w-[600px] p-2 pl-10 text-gray-400 bg-slate-700 sm:rounded-lg  focus:outline-none "
                        placeholder="Search..."
                        autoComplete="off"
                        onChange={(e) => setSearchGift(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="">
                  <table className="w-full text-sm text-left  ">
                    <thead className="text-xs font-medium text-gray-400 bg-gray-50 bg-gray-700 uppercase ">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          {`ID`}
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3  "
                          onClick={sortingData}
                        >
                          Gift Name
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 "
                          onClick={sortingData}
                        >
                          Private/Public
                        </th>
                        <th
                          scope="col"
                          className=" text-center"
                          onClick={sortingData}
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                      {currentData?.length > 0 &&
                        currentData
                          ?.filter((e) => {
                            return searchGift.toLowerCase() === ""
                              ? e
                              : e.folder_name
                                  .toLowerCase()
                                  .includes(searchGift);
                          })
                          .map((e, ind) => (
                            <tr
                              className="bg-gradient-to-r from-[#2f2f49] to-[#191a49] text-white"
                              key={ind}
                            >
                              <th
                                scope="row"
                                className="px-6 py-4 text-gray-100 whitespace-nowrap "
                              >
                                {firstPageIndex + ind + 1}
                              </th>
                              <td
                                // scope="row"
                                className="px-6 py-4 text-gray-200 whitespace-nowrap  capitalize"
                                onClick={() => {}}
                              >
                                {e?.folder_name}
                              </td>

                              <td className="px-6 py-4  text-gray-200  ">
                                {e?.isPublic === true ? "Public" : "Private"}

                                <label
                                  htmlFor={`toggle-${ind}`}
                                  className="flex cursor-pointer select-none items-center"
                                >
                                  <div className="relative">
                                    <input
                                      type="checkbox"
                                      id={`toggle-${ind}`}
                                      className="sr-only"
                                      checked={e.isPublic}
                                      onChange={() => handleToggle(e._id, ind)}
                                    />
                                  </div>
                                </label>
                              </td>
                              <td className="px-6 py-4 text-center flex items-center justify-center">
                                <Link
                                  to={`/user/Gift/${e?._id}`}
                                  type="submit"
                                  className=" cursor-pointer text-center text-green-500 mr-5 rounded-md px-2"
                                >
                                  Edit
                                </Link>
                                <button
                                  type="submit"
                                  className="text-red-700 py-1"
                                  onClick={() => {
                                    OpenPopUp(e?._id);
                                  }}
                                >
                                  <RiDeleteBin6Line size={23} />
                                </button>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                </div>
                <div className="grid place-items-center text-white mt-5">
                  {Array.isArray(AllUsers) && AllUsers.length > 0 ? (
                    <Pagination
                      currentPage={currentPage}
                      totalCount={AllUsers.length}
                      pageSize={PageSize}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  ) : (
                    <p>Data is not found.</p>
                  )}
                </div>
              </>
            )}
            {active === 2 && (
              <>
                <div className="">
                  <div className="flex justify-center items-center mb-5">
                    <div className="relative text-gray-600 focus-within:text-gray-400">
                      <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                        <button
                          type="submit"
                          className="p-1 focus:outline-none focus:shadow-outline"
                        >
                          <svg
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            className="w-6 h-6 text-gray-400"
                          >
                            <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                          </svg>
                        </button>
                      </span>
                      <input
                        type="search"
                        name="q"
                        className="text-lg w-[600px] p-2 pl-10 text-gray-400 bg-slate-700 sm:rounded-lg  focus:outline-none "
                        placeholder="Search..."
                        autoComplete="off"
                        onChange={(e) => setSearchFriend(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <table className="w-full text-sm text-left text-gray-500 ">
                  <thead className="text-xs font-medium text-gray-400 bg-gray-50 bg-gray-700 uppercase ">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        {`ID`}
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 "
                        onClick={sortingData}
                      >
                        Friend Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 "
                        onClick={sortingData}
                      >
                        Friend Date
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center"
                        onClick={sortingData}
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentFriendData?.length > 0 &&
                      currentFriendData
                        ?.filter((f) => {
                          return searchFriend.toLowerCase() === ""
                            ? f
                            : f.name.toLowerCase().includes(searchFriend);
                        })
                        .map((f, index) => (
                          <tr className="bg-gradient-to-r from-[#2f2f49] to-[#191a49] text-white">
                            <th
                              scope="row"
                              className="px-6 py-4 text-gray-200 whitespace-nowrap "
                            >
                              {index + 1}
                            </th>
                            <td
                              scope="row"
                              className="px-6 py-4 text-gray-200 whitespace-nowrap capitalize"
                              onClick={() => {}}
                            >
                              {f?.name}
                            </td>
                            <td className="px-6 py-4 text-gray-200 ">
                              {f?.date}
                            </td>

                            <td className="px-6 py-4 text-center">
                              <button
                                type="submit"
                                className="text-red-700 py-1"
                                onClick={() => {
                                  OpenPopUp(f?._id);
                                }}
                              >
                                <RiDeleteBin6Line size={23} />
                              </button>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
                <div className="grid place-items-center text-white mt-5">
                  {Array.isArray(AllFriend) && AllFriend.length > 0 ? (
                    <Pagination
                      currentPage={currentPage}
                      totalCount={AllFriend.length}
                      pageSize={PageSize}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  ) : (
                    <p>Data is not found.</p>
                  )}
                </div>
              </>
            )}
            {active === 3 && (
              <>
                <div className="">
                  <div className="flex justify-center items-center mb-5">
                    <div className="relative text-gray-600 focus-within:text-gray-400">
                      <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                        <button
                          type="submit"
                          className="p-1 focus:outline-none focus:shadow-outline"
                        >
                          <svg
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            className="w-6 h-6 text-gray-400"
                          >
                            <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                          </svg>
                        </button>
                      </span>
                      <input
                        type="search"
                        name="q"
                        className="text-lg w-[600px] p-2 pl-10 text-gray-400 bg-slate-700 sm:rounded-lg  focus:outline-none"
                        placeholder="Search..."
                        autoComplete="off"
                        onChange={(e) => setSearchEvent(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <table className="w-full text-sm text-left text-gray-500 ">
                  <thead className="text-xs font-medium text-gray-400 bg-gray-50 bg-gray-700 uppercase ">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        {`ID`}
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 "
                        onClick={sortingData}
                      >
                        Event Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 "
                        onClick={sortingData}
                      >
                        Date
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center"
                        onClick={sortingData}
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentCustomData?.length > 0 &&
                      currentCustomData
                        ?.filter((u) => {
                          return searchEvent.toLowerCase() === ""
                            ? u
                            : u.dayName.toLowerCase().includes(searchEvent);
                        })
                        .map((u, index) => (
                          <tr className="bg-gradient-to-r from-[#2f2f49] to-[#191a49] text-white">
                            <th
                              scope="row"
                              className="px-6 py-4 text-gray-200 whitespace-nowrap "
                            >
                              {firstPageIndex + index + 1}
                            </th>
                            <td
                              scope="row"
                              className="px-6 py-4 text-gray-200 whitespace-nowrap  capitalize"
                              onClick={() => {}}
                            >
                              {u?.dayName}
                            </td>
                            <td className="px-6 py-4 text-gray-200 ">
                              {u.dayDate}
                            </td>

                            <td className="px-6 py-4 text-center">
                              <button
                                type="submit"
                                className="text-red-700 py-1"
                                onClick={() => {
                                  OpenPopUp(u?._id);
                                }}
                              >
                                <RiDeleteBin6Line size={23} />
                              </button>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>

                {/* next preves button */}

                {/* <div className="grid place-items-center ">
                <Pagination
                  currentPage={currentPage}
                  totalCount={AllCustom.length}
                  pageSize={PageSize}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div> */}
                <div className="grid place-items-center text-white mt-5">
                  {Array.isArray(AllCustom) && AllCustom.length > 0 ? (
                    <Pagination
                      currentPage={currentPage}
                      totalCount={AllCustom.length}
                      pageSize={PageSize}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  ) : (
                    <p>Data is not found.</p>
                  )}
                </div>
              </>
            )}
          </div>
        </div>

        {/* <<----- Change Status Pop Up ----->> */}
        {DeletePopUp ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[999999]  outline-none focus:outline-none border ">
              <div className="relative min-w-[250px] max-w-[90%] mx-auto  my-10 shadow-black shadow-2xl">
                {/* ------ Content ------ */}
                <div className="border-0 rounded-lg shadow-2xl relative flex flex-col w-full bg-slate-500 outline-none focus:outline-none">
                  {/* ------ Header ------ */}
                  <div className="grid place-items-center place-content-end">
                    <button
                      className="bg-transparent border-0 text-black opacity-9 text-2xl font-normal outline-none focus:outline-none mx-3 my-2"
                      onClick={(e) => ClosePopUp()}
                    >
                      ×
                    </button>
                  </div>
                  {/* ------ Body ------ */}
                  <div className="relative grid place-items-center px-6 md:px-10 py-3 flex-auto">
                    <h3 className="text-black font-semibold text-base md:text-lg  leading-relaxed text-center">
                      Are You Sure ?
                    </h3>
                    <p className="text-black font-medium text-xs md:text-sm  leading-normal text-center mt-1">
                      You want to Delete Story
                    </p>
                  </div>

                  {/* ------ Fotter ------ */}
                  <div className="flex justify-center items-center m-5">
                    <button
                      className="bg-[#18b66c] text-white font-semibold uppercase text-sm px-6 py-3 rounded-lg shadow hover:bg-[#1a965c] hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 mx-2"
                      type="button"
                      onClick={(e) =>
                        ConfirmDelete(
                          SelecteCustom,
                          SelectedUser,
                          SelecteFriend
                        )
                      }
                    >
                      Yes
                    </button>
                    <button
                      className="bg-[#d31e1e] text-white font-semibold uppercase text-sm px-6 py-3 rounded-lg shadow hover:bg-[#cc1616] hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 mx-2"
                      type="button"
                      onClick={(e) => ClosePopUp()}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-20 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default UserData;
